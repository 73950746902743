body{
  font-family: 'Open Sans', sans-serif !important;
}


.box-link {
  text-decoration: none;
  color: white;

}

.box-link:hover {
  text-decoration: none;
  color: white;

}

.logout {
  position: absolute;
  bottom: 0;
  left: 7%;
  transform: translate(-50px, -50px);
  display: flex;
}

.logout .nav-item {
  padding-left: 5.5rem !important;
}

.logout .nav-sidebar:hover {
  background-color: transparent !important;
}

.table th {
  cursor: pointer;
}

.inputwidth {
  width: 120px;
  height: auto;
}

.sparkwidth {
  width: 200px;
}

.icon-copy {
  color: black;
}

.icon-copy:hover {
  color: red;
}

/* all in one css file == > */
@import url(./Components/Assets/css/style.css);

.card-body{
  position: relative;
}



a{
  outline: none;
}