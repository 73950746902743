@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;0,900;1,400;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=PT+Sans:ital@0;1&family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  background-color: #000;
}

:root {
  --normal_bg: #161a22;
  --navFocus: #ffffff1a;
  /* --headerBg: #2C3333; */
  --headerBg: #17202a;
  --liner-bg: linear-gradient(45deg, #e7c14b, #f4ad43);
  --liner-bg2: linear-gradient(45deg, #e7c14b, #f4ad43);
  --black: #000;
  --spinner-bg: linear-gradient(90deg, #e7c14b, #2C3333);
  --active-color: #e7c14b;
  ----cyan-Bg: #e7c14b;
  --body-bg: #081124eb;

}

.content-header h1 {
  font-size: 30px;
}

/* 
  .btn-active{
    background: var(--liner-bg);
  } */



.main-header,
.main-sidebar {
  background: var(--headerBg);
  color: var(--white);
}

.main-header .nav-link {
  color: var(--black);
  transition: all 0.4s linear;
}


/* sidebar css =>  */

.main-sidebar .nav-item {
  margin: 2px 0;
}


.main-sidebar .nav-item .dropdown-menu .nav-item {
  margin: 0;
}

.main-sidebar .nav-link:hover {
  background: var(--liner-bg);
  color: var(--black) !important;
}

.main-sidebar .nav-link:focus {
  background-color: transparent;
  /* color: var(--black) !important; */
}



.sidebar .nav-item .active {
  background: var(--liner-bg);
  color: var(--black) !important;
}



.layout-navbar-fixed .wrapper .sidebar-dark-primary .brand-link:not([class*=navbar]) {
  background-color: transparent;
}



.sidebar .nav-item {
  height: 45px;
  overflow: hidden;
  transition: all 0.4s linear !important;
}

.sidebar .nav-item .dropdown-menu {
  width: 100%;
  background-color: transparent !important;
  position: relative !important;
  top: 0 !important;
  left: -100% !important;
  transform: unset !important;
  margin: 10px 0 0px !important;
  display: block !important;
  height: 0;
  overflow: hidden !important;
  padding: 0px !important;
  border: 0px !important;
  border-radius: 0px !important;
  transition: all 0.4s linear !important;
}

.sidebar .nav-item .dropdown-menu .nav-link {
  color: var(--black);
}

.sidebar .nav-item .dropdown-menu .nav-link:hover {
  color: var(--black);
}

.sidebar .nav-item.show {
  height: max-content;
  left: 0 !important;
  /* background: var(--liner-bg); */
  background: var(--liner-bg2);
  border-radius: 7px;
}

[class*=sidebar-dark-] .nav-sidebar>.dropdown>.nav-item:hover>.nav-link {
  color: var(--black);
  background-color: salmon !important;
}

/* 
[class*=sidebar-dark-] .nav-sidebar>.nav-item.menu-open>.nav-link, [class*=sidebar-dark-] .nav-sidebar>.nav-item:hover>.nav-link{
  color: #a9aeb8;
} */




.sidebar .nav-item.show:nth-child(1) {
  box-shadow: 0px 1px 10px 0px #424141;
  color: #000 !important;

}

.sidebar .nav-item.show .right {
  transform: rotate(90deg);
}

.sidebar .nav-item .show {
  height: 100%;
  left: 0 !important;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background: var(--liner-bg) !important;
  box-shadow: 0px 1px 10px 0px #424141;
  color: #000;

}

.sidebar .user-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transition: all 0.3s linear;
  height: 90px;
}

.sidebar-collapse .sidebar .user-panel {
  transform: scale(0.7);
}

.sidebar-mini.sidebar-collapse .main-sidebar:not(.sidebar-no-expand).sidebar-focused,
.sidebar-mini.sidebar-collapse .main-sidebar:not(.sidebar-no-expand):hover .sidebar .user-panel {
  transform: scale(1);
}

.sidebar .nav-link {
  white-space: nowrap;
}

.sidebar-collapse .sidebar .nav-item.show {
  height: 45px !important;
  background-color: transparent;
}

.sidebar-mini.sidebar-collapse .main-sidebar:not(.sidebar-no-expand).sidebar-focused,
.sidebar-mini.sidebar-collapse .main-sidebar:not(.sidebar-no-expand):hover .sidebar .nav-item.show {
  height: max-content !important;
  background: var(--liner-bg) !important;
}

[class*=sidebar-dark-] .nav-sidebar>.nav-item.menu-open>.nav-link,
[class*=sidebar-dark-] .nav-sidebar>.nav-item:hover>.nav-link,
[class*=sidebar-dark-] .nav-sidebar>.nav-item>.nav-link:focus {
  background-color: transparent;
}

.nav-sidebar>.nav-item>.nav-link:focus {
  color: #000;
}


/* .content-wrapper css ==> */

.content-wrapper {
  /* background-color: var(--normal_bg); */
  background: var(--body-bg);
  color: var(--white);

}


.content .card .img__logo {
  position: absolute;
  bottom: 18px;
  right: 30px;
  transform: scale(1.7) rotate(20deg);
  filter: opacity(0.8);
  z-index: 1;
  width: 36px;
  height: 35px;
}

.content .card .account-balance {
  color: var(----cyan-Bg);
}


.card.card-outline-tabs {
  margin: 0 16px;
}

.card .table th {
  font-size: 13px;
  /* font-family: 'Open Sans', sans-serif; */
  font-weight: 600;
}

.card .table td {
  font-size: 13px;
  /* font-family: 'Open Sans', sans-serif; */
  font-weight: normal;
  /* text-align: center; */
}


.main-footer {
  background: var(--headerBg);
  color: var(--white);
}




.card .btn-active {
  background-color: var(--headerBg);
  border: 1px solid #e7c14b;
  color: #fff;
  margin: 0 3px !important;
  padding: 0 10px;
  height: calc(1.8rem + 2px);
}

.card .form-group select {
  background-color: var(--headerBg);
  border: 1px solid var(----cyan-Bg);
  color: #fff;
  margin: 0 3px !important;
  padding: 0;
  height: calc(1.8rem + 2px);
}


.card .card-tools .input-group {
  border: 1px solid var(----cyan-Bg);
  border-radius: 4px;
}

.card .card-tools .input-group input {
  background-color: transparent;
  border: 0px;
  border-radius: 0px;
  height: calc(1.60rem + 2px);
  color: var(--white);
}

.card .card-tools .input-group input::placeholder {
  color: #ededed;
}

.card .card-tools .input-group .btn {
  background-color: transparent;
  color: #fff;
  border: 0px;

}



.card {
  padding: 30px;
  background: var(--headerBg);
  overflow: hidden;
  color: var(--white);

}


.mb-11 {
  margin-bottom: 9rem !important;
}

.pagination {
  /* background-color: teal; */
  margin-top: 30px;
  font-family: 'Open Sans', sans-serif;
}

.pagination a {
  background-color: transparent !important;
  margin: 0 5px;
  border-radius: 3px;
  border-color: #5e5e5e;
  color: var(--white);
  /* padding: 0; */
}

.pagination .active .page-link {
  background: var(--liner-bg);
  border-color: transparent;
}

.pagination .page-link:hover:not(.disabled) {
  background: var(--liner-bg) !important;
}



/* spinner css  */




.spinner-main {
  width: 100%;
  height: 300px;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.spinner-main .spinnerWrap {
  width: 100%;
}

.spinner_ds {
  height: 300px;
}

.spinner-container>div {
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  margin: auto;
  width: 50px;
  height: 50px;
  background: var(--spinner-bg);
}


#spinner5 {
  position: relative;
  animation: deform 2s linear infinite;
}

@keyframes deform {
  0% {
    border-radius: 0%;
    transform: rotate(0deg) scale(1);
  }

  50% {
    border-radius: 100%;
    transform: rotate(180deg) scale(0.8);
  }

  100% {
    border-radius: 0%;
    transform: rotate(360deg) scale(1);
  }
}


.content .spinner-main {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-content: center;
  height: auto;
  width: auto;
  position: absolute;
  z-index: 99999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.spinner-main .spinnerWrap {
  width: 100%;
}

.spinner-main .spinnerWrap p {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.spinner-main .spinnerWrap p span {
  position: relative;
  top: -4px;
  left: -3px;
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 3px;
  visibility: hidden;
  margin: 0 2px;
  animation: opecity 1s linear infinite;
  background-color: #000;
}

.spinner-main .spinnerWrap p .dot-1 {
  animation-delay: 0.3s;
}

.spinner-main .spinnerWrap p .dot-2 {
  animation-delay: 0.4s;

}

.spinner-main .spinnerWrap p .dot-3 {
  animation-delay: 0.5s;

}

.spinner-main .spinnerWrap p .dot-4 {
  animation-delay: 0.6s;
}



@keyframes opecity {
  0% {
    visibility: hidden;
  }

  100% {
    background: var(--liner-bg);
    visibility: visible;
  }

  /* 100%{
    visibility: hidden;
  } */

}


/* spinner css end */

.text-link {
  color: var(--white);
}

.text-link:hover {
  color: var(--active-color);
}

.icon-copy i {
  background: var(--liner-bg);
  padding: 7px;
  margin-left: 10px;
  border-radius: 4px;
  color: var(--black);
  border: 1px solid transparent;
  transition: all 0.3s linear;
}

.icon-copy i:hover {
  border-color: var(--active-color);
  color: var(--white);
  background: transparent;
}

.btn-style {
  background: var(--liner-bg);
  padding: 2px 15px;
  border: 1px solid transparent;
  font-size: 14px;
  transition: all 0.3s linear;
}

.btn-style:hover {
  background: transparent;
  border-color: var(--active-color);
  color: var(--white);
}


.login-page {
  background-color: var(--body-bg);
}

.login-page .login-logo a {
  color: var(--white);
}

.login-page .login-card-body {
  padding: 0;
  background-color: transparent;
  color: var(--white);
}

.login-page .login-card-body .btn {
  width: 60% !important;
  margin: auto;
  display: block !important;
  padding: 7px 20px;
}

.login-page .login-card-body .login__picture img {
  width: 180px;
  height: auto;
  margin: auto;
}



.create-token textarea {
  padding: 10px 10px;
  height: 45px;
  background-color: transparent !important;
  border-color: var(--active-color);
  color: var(--white) !important;
  border-radius: 6px;
  height: 100px;

}

.create-token input {
  padding: 10px 10px;
  height: 45px;
  background-color: transparent;
  border-color: var(--active-color);
  color: var(--white) !important;
  border-radius: 6px;
  -moz-appearance: textfield !important;
}



.create-token input::-webkit-outer-spin-button,
.create-token input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.create-token input::placeholder {
  color: #ddd;
}

.create-token input:focus {
  background-color: transparent;
  box-shadow: unset;
  outline: none;
  border-color: var(--white);
}

.create-token .btn {
  width: 150px !important;
  display: block !important;
  padding: 10px 20px;
}

.create-token .btn-withdraw {
  width: 150px;
  height: auto;
  padding: 12px 10px;
  background: var(--liner-bg);
  border: 1px solid var(--active-color);
  color: #000;
  transition: all 0.3s linear;
}

.create-token .btn-withdraw:hover {
  background: transparent;
  color: #fff;
}


.create-token .form-group select {
  background: var(--headerBg);
  border: 1px solid var(----cyan-Bg);
  color: #fff;
  margin: 0 3px !important;
  padding: 0;
  height: calc(2.5rem + 2px);
}

.create-token .form-group label {
  margin-bottom: 7px !important;
}

.with__form-card {
  width: 40%;
  transition: all 0.3s linear;
}

.with__form-card .form-c {
  height: 80px;
}


.content-wrapper-new {
  margin-left: 250px;
  margin-top: 60px;
  transition: margin-left .3s ease-in-out;
}

.sidebar-collapse .content-wrapper-new {
  margin-left: 85px;
}

.content-header {
  color: var(--white);
}



.show-option {
  display: none;
}

/* .card .table td {
  text-align: center;
} */

.column-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.column-main .column-right-s {
  width: 100%;
  margin-left: 10px;
}

.content-wrapper_1 {
  margin: 0 !important;
  display: block;
  overflow: scroll;
  background: transparent !important;
  height: calc(100vh - calc(3.5rem + 1px) - calc(3.5rem + 1px)) !important;
}


thead {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: var(--headerBg);
}

.table-responsive {
  overflow-y: scroll;
}

.table-responsive::-webkit-scrollbar {
  height: 10px;
  width: 5px;
}

.table-responsive::-webkit-scrollbar-thumb {
  background-color: var(----cyan-Bg);
}

.customModal_S .title-modal {
  font-size: 18px;
}

.customModal_S .input-group {
  margin-bottom: 10px;
  background-color: #ededed;
  /* width: 400px; */
  width: 100%;
}

.customModal_S .input-group .input-group-text {
  background: transparent;
  border: 0px;
  padding: 5px 10px;
}

.customModal_S .input-group select {
  background-color: transparent;
  border: 0px;
  padding: 0;
}



.customModal_S .input-group input {
  -moz-appearance: textfield !important;
  background-color: #ededed;
}




.customModal_S .input-group input::-webkit-outer-spin-button,
.customModal_S .input-group input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.customModal_S .input-group .btn_max {
  outline: none;
  border-radius: 5px;
  background: green;
  border: 0px;
  padding: 6px 10px;
  color: #fff;
}


.trade-btn-a {
  background: var(--liner-bg);
  transition: all 0.4s linear;
  border: 1px solid transparent;
  color: #fff;
}

.trade-btn-a:hover {
  background: transparent;
  color: #000;
  border-color: var(----cyan-Bg);
}


.customModal_Sa thead {
  background: #ededed;

}


.react-responsive-modal-modal {
  width: 80% !important;
  margin-right: 20px !important;
  margin-top: 60px;
}

.customModal_Sa .modail-data .table-responsive {
  height: 300px !important;
  overflow-y: scroll;
  overflow-x: hidden;
}

.customModal_Sa .modail-data .table-responsive thead {
  position: sticky;
  top: -3px;
}

.customModal_Sa .modail-data .table-responsive thead th {
  text-align: center;
}

.customModal_Sa .modail-data .table-responsive tbody td {
  text-align: center;
}


.customModal_Sa .list-modal-a {
  margin: 20px 0;
}

.customModal_Sa .list-modal-a .input-group {
  margin-bottom: 10px;
  background-color: #ededed;
  /* width: 400px; */
  width: 100%;
}

.customModal_Sa .list-modal-a .input-group .input-group-text {
  background: transparent;
  border: 0px;
  padding: 5px 10px;
}

.customModal_Sa .list-modal-a .input-group select {
  background-color: transparent;
  border: 0px;
  padding: 0;
}


.layout-navbar-fixed .wrapper .main-header,
.main-footer {
  z-index: 4 !important;
}

.main-sidebar {
  z-index: 2 !important;
}




.Rejected_modal_Main{
  max-width: 600px !important;
  width: 100% !important;
}

.Rejected_modal_Main form{
  margin: 40px 0;
}

.Rejected_modal .form-gorup{
  /* background-color: saddlebrown; */

}

.Rejected_modal .form-gorup label{
  display: block;
}
.Rejected_modal .form-gorup input{
  width: 100%;
  padding: 10px;
  border: 1px solid #000;
  outline: none;
  box-shadow: none;
  border-radius: 5px;
  background-color: #dcdcdc42;
}

.button___area_ma{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}


.button___area_ma button{
  padding: 10px 20px !important;
  margin: auto;
  text-align: center;
  background: var(--liner-bg);
  width: 60%;
  border: 1px solid transparent;

}

.button___area_ma button:hover{
  background: transparent;
  border-color: var(----cyan-Bg);
}






@media (max-width:1200px) {
  .with__form-card {
    width: 70%;
  }
}



@media (max-width:992px) {

  .content-wrapper-new {
    margin-left: 0px;

  }

  .sidebar-collapse .content-wrapper-new {
    margin-left: 0px;

  }
}




@media (max-width:768px) {
  .card-tools {
    margin-top: 10px !important;
  }

  .with__form-card {
    width: 100%;
  }
}





@media (max-width:576px) {
  .card.card-outline-tabs {
    margin: 0 5px;
  }

  .card {
    padding: 20px 10px;
  }

  .content-header h1 {
    font-size: 18px;
  }

  .content-header .breadcrumb {
    font-size: 15px;
  }

  .dataTables_info {
    font-size: 14px;
  }

  .pagination a {
    padding: 5px 10px;
    font-size: 12px;
  }


  .login-page .login-card-body .login__picture img {
    width: 150px;
    height: auto;
    margin: auto;
  }


  .content-wrapper-new {
    margin-left: 0px;

  }

  .sidebar-collapse .content-wrapper-new {
    margin-left: 0px;
  }

  .column-main {
    flex-direction: column;
  }

  .column-main .column-right-s {
    margin-left: 0px;
  }

  .form-group {
    margin-bottom: 5px;
  }

  .create-token .form-group label {
    font-size: 14px;
  }
  .customModal_Sa{
    margin-top: 60px;
  }
  .customModal_Sa .title-modal{
    font-size: 13px !important;
  }

  .customModal_Sa .table thead th {
    font-size: 11px;
  }

  .customModal_Sa .table tbody td {
    font-size: 13px;

  }




}